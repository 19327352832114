@import url(uikit.min.css);

@font-face {
  font-family: 'Lausanne';
  src: url(assets/Lausanne-Regular.otf) format('opentype');
}

$light: #FFFCF6;
$dark: #000;

*{
  box-sizing: border-box;
}

html, body{
  background-color: $light;
  font-family: 'Lausanne', sans-serif;
  border:0;
  padding:0;
  margin:0;
}

.uk-h1, .uk-h2, .uk-h3, .uk-h4, .uk-h5, .uk-h6, .uk-heading-2xlarge, .uk-heading-3xlarge, .uk-heading-large, .uk-heading-medium, .uk-heading-small, .uk-heading-xlarge, h1, h2, h3, h4, h5, h6 {
  font-family: 'Lausanne', sans-serif;
  color: $dark;
}





.App-logo {
 
  max-width: 250px;
}

img {max-width: 100%;}

h1{
  text-transform: uppercase;
  font-size: 5rem;
  font-weight: 400;
}

// 404


.four04{
  position: absolute;
  height: 100vh;
  top: 0;
  width: 100vw;
  background-size: cover;
  background-position: center;
  z-index: 0;
  text-align: center;
}

.four04 + div{
display: none;
}



// mobile
@media (max-width: 800px) {
 h1{
   font-size: 2rem;
 }
}


//HOME
.collection{
  h4{
    text-transform: uppercase;
    margin:0;
  }

  p{
    margin-top:0;
  }
}

.collection-image-thumb{
  position: absolute;
    bottom: 0;
}

.collections{
  margin:0;
}
